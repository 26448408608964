





















import { ProfileModel } from '@/models/profile-model'
import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {},
})
export default class extends Vue {
  @Prop({}) profile!: ProfileModel
  @Prop({ default: false }) isAdmin!: boolean
}
